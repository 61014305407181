import React from 'react';
import PropTypes from 'prop-types';

const Input = React.forwardRef(({ label, placeholder, type = "text", error, ...inputProps }, ref) => {
  return (
    <div className="mb-3">
      {label && <label className="form-label">{label}</label>}
      <input
        ref={ref}
        className={`form-control ${error ? 'is-invalid' : ''}`}
        placeholder={placeholder}
        type={type}
        {...inputProps}
      />
      {error && <div className="invalid-feedback">{error.message}</div>}
    </div>
  );
});

Input.displayName = 'Input';

Input.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  error: PropTypes.object,
};

export default Input;

import React from 'react';
import PropTypes from 'prop-types';
import styles from './formUserProfile.module.css';
import Input from '../../Input';
import { roAPI } from '#utils/axiosAPI';
import { useForm } from "react-hook-form";

const FormUserProfile = ({ currentUser, afterSubmit }) => {
  const { register, handleSubmit, formState: { errors }, watch } = useForm({
    defaultValues: currentUser,
  });

  const onSubmit = async (values) => {
    try {
      await roAPI.put('/user', {
        name: values.name,
        last_name: values.last_name,
        ...(values.phone_number && { phone_number: values.phone_number }),
        ...(values.password && { password: values.password }),
      });

      if (values.email && currentUser.email !== values.email) {
        await roAPI.put('/user/email', {
          email: values.email,
        });
      }
      afterSubmit();
    } catch (error) {
      // Handle error
    }
  };

  return (
    <div data-testid="FormUserProfile">
      <form id="formUser" onSubmit={handleSubmit(onSubmit)} className={`row g-3 ${styles.form}`}>
        <div className="col-md-4">
          <Input
            label="Nombre"
            placeholder="Nombre"
            type="text"
            {...register('name', { required: 'Este campo es requerido' })}
            error={errors.name}
          />
        </div>

        <div className="col-md-4">
          <Input
            label="Apellidos"
            placeholder="Apellidos"
            type="text"
            {...register('last_name', { required: 'Este campo es requerido' })}
            error={errors.last_name}
          />
        </div>

        <div className="col-md-4">
          <Input
            label="Correo electrónico"
            placeholder="Correo electrónico"
            type="email"
            {...register('email', {
              required: 'Este campo es requerido',
              pattern: {
                value: /^\S+@\S+$/i,
                message: 'Correo electrónico inválido',
              },
            })}
            error={errors.email}
          />
        </div>

        <div className="col-md-4">
          <Input
            label="Número de télefono"
            placeholder="Número de télefono"
            type="text"
            {...register('phone_number', {
              pattern: {
                value: /^\d{7,10}$/,
                message: 'Número de teléfono inválido',
              },
            })}
            error={errors.phone_number}
          />
        </div>

        <div className="col-md-4">
          <Input
            label="Contraseña"
            placeholder="Contraseña"
            type="password"
            {...register('password')}
            error={errors.password}
          />
        </div>

        <div className="col-md-4">
          <Input
            label="Confirmar contraseña"
            placeholder="Confirmar contraseña"
            type="password"
            {...register('password2', {
              validate: value => value === watch('password') || 'La contraseña no coincide',
            })}
            error={errors.password2}
          />
        </div>

        <div className="col-12">
          <p style={{ color: '#777' }}>* Si no desea cambiar su contraseña deje los campos vacíos.</p>
        </div>

      </form>

      <button type="submit" form="formUser" className={`btn btn-une ${styles['btn-submit']}`}>
        Guardar cambios
      </button>

    </div>
  );
};

FormUserProfile.propTypes = {
  currentUser: PropTypes.any,
  afterSubmit: PropTypes.func,
};

FormUserProfile.defaultProps = {
  currentUser: {},
  afterSubmit: f => f,
};

export default FormUserProfile;

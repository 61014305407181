import React from 'react';
import PropTypes from 'prop-types';
import Input from '../../Input';
import styles from './formChangePassword.module.css';
import { useParams } from 'react-router-dom';
import { connect as reduxConnect } from 'react-redux';
import { actions } from '#redux/reducers';
import { roAPI } from '#utils/axiosAPI';
import { useForm } from 'react-hook-form';

const FormChangePassword = ({ onAfterSubmit, alert }) => {
  const { email, code } = useParams();
  const { register, handleSubmit, formState: { errors }, watch } = useForm();

  const onSubmit = async (values) => {
    try {
      await roAPI.post('/auth/change/password/email', {
        email: decodeURIComponent(email),
        code: decodeURIComponent(code),
        password: values.password,
      });
      onAfterSubmit('/auth/inicio', 'redirect');
      alert({
        type: 'success',
        title: '¡Cambio de contraseña exitoso!',
        message: 'Por favor inicia sesión con tu nueva contraseña.',
      });
    } catch (error) {
      // Handle error
    }
  };

  return (
    <div data-testid="FormLogin">
      <form id="formLogin" onSubmit={handleSubmit(onSubmit)} className={`row g-3 ${styles.form}`}>
        <div className="col-12">
          <Input
            label="Contraseña"
            placeholder="Contraseña"
            type="password"
            {...register('password', { required: 'Este campo es requerido' })}
            error={errors.password}
          />
        </div>

        <div className="col-12">
          <Input
            label="Confirmar contraseña"
            placeholder="Confirmar contraseña"
            type="password"
            {...register('password2', {
              required: 'Este campo es requerido',
              validate: value => value === watch('password') || 'La contraseña no coincide',
            })}
            error={errors.password2}
          />
        </div>
      </form>

      <button type="submit" form="formLogin" className={`btn btn-une ${styles['btn-submit']}`}>
        Cambiar contraseña
      </button>
    </div>
  );
};

FormChangePassword.propTypes = {
  onAfterSubmit: PropTypes.func,
  alert: PropTypes.func,
};

FormChangePassword.defaultProps = {
  onAfterSubmit: f => f,
  alert: f => f,
};

export default reduxConnect(
  () => ({}),
  dispatch => ({
    alert: alert => dispatch(actions.alert(alert)),
  }),
)(FormChangePassword);

import React from 'react';
import PropTypes from 'prop-types';
import { BrowserView, MobileView } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faEdit, faBars } from '@fortawesome/free-solid-svg-icons';
import { faFacebookSquare, faGoogle } from '@fortawesome/free-brands-svg-icons';
import moment from 'moment';
import MapSidebar from '#components/navbars/mapSidebar/mapSidebar';
import TextDivider from '#components/random/textDivider/textDivider';
import FacebookAuth from '#components/forms/social/facebookAuth';
import GoogleAuth from '#components/forms/social/googleAuth';
import FormUserProfile from '#components/forms/formUserProfile/formUserProfile';
import LayoutOnWidth from '#components/HOCs/layoutOnWidth/layoutOnWidth';
import { isIFrame } from '#utils/utils';
import { roAPI } from '#utils/axiosAPI';
import AlertsHOC from '#components/HOCs/alertsHOC/alertsHOC';
import styles from './profile.module.css';

const Profile = () => {
  const [showSidebar, setShowSidebar] = React.useState(false);
  const toggleSidebar = () => setShowSidebar(prevState => !prevState);

  const [editing, setEditing] = React.useState(false);

  const [user, setUser] = React.useState({
    id_user: '0',
  });

  const getUser = React.useCallback(async () => {
    try {
      const resp = await roAPI.get('user');
      setUser(resp);
    } catch (error) {
      //
    }
  }, []);

  React.useEffect(() => {
    getUser();
  }, [getUser]);

  const onFacebookConnect = async (facebook) => {
    try {
      await roAPI.post('/user/add/facebook', {
        access_token: facebook.accessToken
      });
      getUser();
    } catch (error) {
      //
    }
  };

  const onGoogleConnect = async (google) => {
    if (google.error) { return; }
    try {
      await roAPI.post('/user/add/google', {
        access_token: google.accessToken
      });
      getUser();
    } catch (error) {
      //
    }
  };

  const unlinkSocial = async (social) => {
    try {
      await roAPI.delete('/user/social', {
        params: {
          type: social,
        },
      });
      getUser();
    } catch (error) {
      //
    }
  };

  const afterSubmit = () => {
    setEditing(false);
    getUser();
  };

  const elChuki = (
    <React.Fragment>
      {!editing && (
        <div className="row">

          <div className="col-12 col-md-4">
            <div className="item-desc">
              <span className="subtitle">Nombre completo</span>
              <span className="value">{user.name} {user.last_name}</span>
            </div>
          </div>

          <div className="col-12 col-md-4">
            <div className="item-desc">
              <span className="subtitle">Correo electronico</span>
              <span className="value">{user.email || <span className={styles['light']}>Sin correo registrado</span>}</span>
            </div>
          </div>

          <div className="col-12 col-md-4">
            <div className="item-desc">
              <span className="subtitle">Teléfono</span>
              <span className="value">{user.phone_number || <span className={styles['light']}>Sin teléfono registrado</span>}</span>
            </div>
          </div>

          <div className="col-12 col-md-4">
            <div className="item-desc">
              <span className="subtitle">Fecha de registro</span>
              <span className="value">{moment(user.created_at).format('DD MMMM YYYY hh:mm a')}</span>
            </div>
          </div>

          <div className="col-12 col-md-4">
            <div className="item-desc">
              <span className="subtitle">Cuenta verificada</span>
              <span className="value">
                {user.activated
                  ? <span className="badge badge-success">
                    <FontAwesomeIcon icon={faCheck} style={{ marginRight: '.5rem' }} />
                    Verificada
                  </span>
                  : <span className="badge badge-danger">
                    <FontAwesomeIcon icon={faTimes} style={{ marginRight: '.5rem' }} />
                    Sin verificar
                  </span>
                }
              </span>
            </div>
          </div>
        </div>
      )}

      {editing && (
        <FormUserProfile key={user.id_user} currentUser={user} afterSubmit={afterSubmit} />
      )}

      <TextDivider text="Redes sociales" />

      <div className="row">
        <div className="col-12 col-md-6">
          <div className="item-desc">
            <span className="subtitle">Facebook</span>
            <span className="value">
              {user.facebook_id
                ? <>
                  <span className={`badge badge-primary ${styles['fb-badge']}`}>
                    <FontAwesomeIcon icon={faFacebookSquare} style={{ marginRight: '.5rem' }} />
                    Conectado - {user.facebook_id}
                  </span>
                  <button type="button" className={`btn btn-sm btn-link ${styles['unlink']}`} onClick={() => unlinkSocial('facebook')}>
                    Desconectar
                  </button>
                </>
                : <FacebookAuth
                  text="Conectar con Facebook"
                  onFacebookLogin={onFacebookConnect}
                />
              }
            </span>
          </div>
        </div>

        <div className="col-12 col-md-6">
          <div className="item-desc">
            <span className="subtitle">Google</span>
            <span className="value">
              {user.google_id
                ? <>
                  <span className={`badge badge-light ${styles['google-badge']}`}>
                    <FontAwesomeIcon icon={faGoogle} style={{ marginRight: '.5rem' }} />
                    Conectado - {user.google_id}
                  </span>
                  <button type="button" className={`btn btn-sm btn-link ${styles['unlink']}`} onClick={() => unlinkSocial('google')}>
                    Desconectar
                  </button>
                </>
                : <GoogleAuth
                  text="Conectar con Google"
                  onGoogleLogin={onGoogleConnect}
                />
              }
            </span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );

  return (
    <>
      <BrowserView>
        <div className={`card mirai-shadow ${styles['profile-card']} ${isIFrame() && styles['iframe']}`}>
          <MapSidebar show showLogo forceMinWidth />
          <div className={`card-body ${styles['card-body']}`}>

            <div className={styles['header']}>
              <h2>Mi perfil</h2>
              {!editing && (
                <button type="button" className="btn btn-link" onClick={() => setEditing(true)}>
                  <FontAwesomeIcon icon={faEdit} style={{ marginRight: '.5rem' }} />
                  Editar información
                </button>
              )}
              {editing && (
                <button type="button" className="btn btn-link" onClick={() => setEditing(false)}>
                  <FontAwesomeIcon icon={faTimes} style={{ marginRight: '.5rem' }} />
                  Cancelar edición
                </button>
              )}
            </div>

            {elChuki}

          </div>
        </div>
      </BrowserView>

      <MobileView>
        <div className={`card ${styles['profile-mobile-card']}`}>
          <div className={styles['left']}>
            <MapSidebar show={showSidebar} close={toggleSidebar} />
          </div>
          <div className={`card-body ${styles['card-mobile-body']}`}>

            <div className={styles['header-mobile']}>
              <button type="button" className={`btn btn-light`} disabled={showSidebar} onClick={toggleSidebar}>
                <FontAwesomeIcon icon={faBars} />
              </button>
              <h2 className={styles['title-mobile']}>Mi perfil</h2>
              {!editing && (
                <button type="button" className="btn btn-link" onClick={() => setEditing(true)}>
                  <FontAwesomeIcon icon={faEdit} style={{ marginRight: '.5rem' }} />
                  Editar
                </button>
              )}
              {editing && (
                <button type="button" className="btn btn-link" onClick={() => setEditing(false)}>
                  <FontAwesomeIcon icon={faTimes} style={{ marginRight: '.5rem' }} />
                  Cancelar
                </button>
              )}
            </div>

            {elChuki}

          </div>
        </div>
      </MobileView>
    </>
  );
};

Profile.propTypes = {
  errorByType: PropTypes.func,
};

Profile.defaultProps = {
  errorByType: f => f,
};

const profileLyout = LayoutOnWidth(Profile, {
  browser: {
    backgroundStyle: {
      backgroundColor: '#f5f5f5',
      height: '100vh',
    },
    containerStyle: {
      height: '100%',
      alignItems: 'center',
    },
    rowStyle: {
      height: '100%',
      alignItems: 'center',
    },
    rowClass: 'row justify-content-center',
    colClass: 'col-12'
  },
});

export default AlertsHOC(profileLyout);
